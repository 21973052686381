/* eslint-disable */
// ./gatsby-browser.js
require("./src/styles/global.css");

const React = require("react");

if (process.env.NODE_ENV !== "development") {
  console.log("Starting BugSnag");
  const Bugsnag = require("@bugsnag/js");
  const BugsnagPluginReact = require("@bugsnag/plugin-react");

  Bugsnag.start({
    apiKey: process.env.GATSBY_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
  });

  const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
  exports.wrapRootElement = ({ element }) => (
    <ErrorBoundary>{element}</ErrorBoundary>
  );
} else {
  console.log("In development, skipping BugSnag");
}
