exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-california-do-not-sell-jsx": () => import("./../../../src/pages/california-do-not-sell.jsx" /* webpackChunkName: "component---src-pages-california-do-not-sell-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lessons-group-jsx": () => import("./../../../src/pages/lessons/group.jsx" /* webpackChunkName: "component---src-pages-lessons-group-jsx" */),
  "component---src-pages-lessons-private-jsx": () => import("./../../../src/pages/lessons/private.jsx" /* webpackChunkName: "component---src-pages-lessons-private-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-tutorapply-jsx": () => import("./../../../src/pages/tutorapply.jsx" /* webpackChunkName: "component---src-pages-tutorapply-jsx" */),
  "component---src-templates-blog-page-jsx": () => import("./../../../src/templates/blog-page.jsx" /* webpackChunkName: "component---src-templates-blog-page-jsx" */),
  "component---src-templates-lang-page-jsx": () => import("./../../../src/templates/lang-page.jsx" /* webpackChunkName: "component---src-templates-lang-page-jsx" */)
}

